import styled from '@emotion/styled';
import { breakPoints } from 'constant/breakPoints';
import Slider from 'react-slick';
import { RectangleBlur } from 'styles/styled';

function VisionCarousel(): JSX.Element {
  return (
    <WrapMark>
      <RectangleBlur
        width="397.76px"
        rotate={45.07}
        right="-220px"
        bottom="-220px"
      />
      <RectangleBlur width="450px" rotate={-128.38} left="-220px" top="90px" />

      <Layout className="layout-wrap">
        <Wrap>
          <Slider
            swipeToSlide
            accessibility
            swipe
            dots
            arrows={false}
            autoplay
            slidesToShow={1}
          >
            <Item>
              <h4 className="font-semibold">Our Vision</h4>
              <p className="title1 font-normal">
                A Global Digital Partner assists SMEs, Startup and Entrepreneur
                to improve processes through software development by the dignity
                and expertise of talents in tech.
              </p>
            </Item>

            <Item>
              <h4 className="font-semibold">Our Mission</h4>
              <p className="title1 font-normal">
                We provide cost-effective and scalable solutions to create and
                launch game-changing digital products.
              </p>
            </Item>
          </Slider>
        </Wrap>
      </Layout>
    </WrapMark>
  );
}

export default VisionCarousel;

const WrapMark = styled.div`
  position: relative;

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    & > div:nth-of-type(1) {
      display: none;
    }

    & > div:nth-of-type(2) {
      left: -340px;
      top: -260px;
    }
  }
`;

const Layout = styled.section`
  max-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 227px;
  padding-bottom: 156px;

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    padding-top: 20px;
    padding-bottom: 50px;
    min-height: auto;
  }
`;

const Wrap = styled.div`
  padding: 60px 15px 90px;
  width: 100%;

  .slick-slider {
    max-width: 780px;
    margin: 0 auto;
  }

  .slick-dots {
    text-align: left;
    transform: translateY(24px);

    li {
      border: 1px solid var(--white);
      border-radius: 50%;
      margin: 0 24px 0 0;
      width: 12px;
      height: 12px;

      button:before {
        display: none;
      }

      &.slick-active {
        background-color: var(--white);
      }
      &.slick-active button:before {
        color: var(--white);
      }
    }
  }
`;

const Item = styled.div`
  h4 {
    font-size: 40px;
    margin: 0 0 16px;
  }

  p {
    font-size: 24px;
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    h4 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }
  }
`;
